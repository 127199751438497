import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>From Ashes to Answers</h2>
          <h5 className={classes.description}>
            With years of experience and a deep understanding of the complex nature of fire and explosion investigations, we are equipped to provide our clients with the highest quality investigation and analysis services. 
            <br/>
            <br/>
            Our team is dedicated to uncovering the root cause of each incident and providing our clients with the information and insights they need to make informed decisions. From initial scene assessment to evidence collection and analysis, our investigators are committed to delivering reliable and accurate results to help our clients achieve their goals. 
            <br/>
            <br/>
            We are committed to providing timely, professional and cost-effective services to meet the needs of our clients. Contact us today to learn more about how we can assist you with your fire and explosion investigation needs.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Dependable"
              description="At Tetra Fire and Explosion Investigation Company, we understand that industry professionals require dependable and reliable services to ensure that their clients receive the compensation they deserve."
              icon={Chat}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Experienced"
              description="We utilize state-of-the-art equipment and techniques to conduct our investigations, and we maintain a strong commitment to the highest standards of safety, integrity, and professionalism"
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Individual Focused"
              description="We are committed to providing our clients with the highest level of service, and we are always available to answer any questions or concerns they may have. Contact us today to learn more about our services and how we can help you."
              icon={Fingerprint}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
