import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import AboutSection from "./Sections/AboutSection.js";
import WorkSection from "./Sections/WorkSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      {/*<img className ={classes.logo} src={require("assets/img/tetra_logo.png")} alt="Tetra Fire Investigation Services Logo" />*/}
    {/*<div className={classes.container3}>*/}
      {/*<img className ={classes.logo2} src={require("assets/img/tetra_logo.png")} alt="Tetra Fire Investigation Services Logo" />*/}
              {/*<h1 className={classes.title2}>TETRA</h1>*/}
              {/*<h2 className={classes.title3}>Fire Investigations</h2>*/}
              {/*</div>*/}
    <div className={classes.container2}>
              <h1 className={classes.title2}>TETRA</h1>
              <h2 className={classes.title3}>Fire Investigations</h2>
    <div className={classes.container3}>
      <img className ={classes.logo2} src={require("assets/img/tetra_logo.png")} alt="Tetra Fire Investigation Services Logo" />
              </div>
              </div>
      <Parallax filter image={require("assets/img/firehazard.png")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
                {/*<h1 className={classes.title}>Dedicated to helping individuals, not insurance companies</h1>*/}
                        {/*<img className ={productStyles.logo} src={require("assets/img/tetra_logo.png")} alt="Tetra Fire Investigation Services Logo" />*/}
                        {/*<img src="assets/img/new_logo.png" alt="Creative Tim Logo"/> */}
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
    {/*<div className={classes.container3}>*/}
      {/*<img className ={classes.logo2} src={require("assets/img/tetra_logo.png")} alt="Tetra Fire Investigation Services Logo" />*/}
              {/*<h1 className={classes.title2}>TETRA</h1>*/}
              {/*<h2 className={classes.title3}>Fire Investigations</h2>*/}
              {/*</div>*/}
        <div className={classes.container}>
          <ProductSection />
          {/*<AboutSection />*/}
          <WorkSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
