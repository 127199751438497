import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/faces/christian.jpg";

import backdrop from "assets/img/backdrop.jpg";

// @material-ui/icons
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Chat from "@material-ui/icons/Chat";
import ImportContacts from "@material-ui/icons/ImportContacts";
import Waves from "@material-ui/icons/Waves";
import MergeType from "@material-ui/icons/MergeType";
import Home from "@material-ui/icons/Home";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Person from "@material-ui/icons/Person";
import Call from "@material-ui/icons/Call";
// core components
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand="Tetra Fire Investigation Services"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/miami-bg-2.png")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={backdrop} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h2 className={classes.title}>Who Are We?</h2>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <h3>
                  A public adjuster works for the insured, <b>NOT</b> the insurance company.

                  <br/>
                  <br/>
                  <h4>
                  We provide the insurance company with a detailed review of all damage to your property in the form of an estimate.
                      <br/><br/>This estimate is prepared with line items and pricing. All quantities, unit costs, and total costs to cover repairs are provided. All forms used are industry standard forms, we attend all meetings with adjusters and contractors so you can resume your normal schedule.
                      <br/><br/>You are kept apprised of all communications and outcomes on a regular basis, including all details and action items of each meeting. We will communicate all settlement offers to you and offer recommendations whether to accept or negotiate further.
                      <br/><br/>Settlements are accepted only after your consent. In the event we cannot come to an agreement with your insurance company we may go to appraisal or further to litigation.  Our job is to obtain all that is legally due to you from the insurance company.</h4>

                  <br/>
                  <br/>
                  <h4>
                      These are typical questions you will be asked and then we set up a meeting to personally view and assess the damages in question. We will go over your policy with you, explaining all coverage and help you understand your requirements as an insured.
                      <br/>
                      <br/>
                      This is when you will decide if you wish to pursue a claim and you will sign a form that indicates we will represent you.
                  </h4>

              </h3>
            </div>
              <div>
                  <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                          <InfoArea
                              title="How it Starts?"
                              description="When you call us, your call is answered by a knowledgeable PA who will begin the process of making your loss a thing of the past."
                              icon={Call}
                              iconColor="info"
                              vertical
                          />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                          <InfoArea
                              title="What to expect?"
                              description="We begin by getting to know the disaster from you directly. From there we work to understand your options and figure out what your insurance company will be looking for."
                              icon={Waves}
                              iconColor="success"
                              vertical
                          />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                          <InfoArea
                              title="Types of Questions"
                              description="Every Situation is different in its own way, but typical questions you can expect when you first call us range from 'Did the damage occur in your home or business?' or 'Was the property insured? If so, by whom?' "
                              icon={MergeType}
                              iconColor="danger"
                              vertical
                          />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                          <InfoArea
                              title="What Happened?"
                              description="Was there a storm? Was there a fire? Was there a sewage backup? These are only some of the many type of disasters we look into."
                              icon={Home}
                              iconColor="info"
                              vertical
                          />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                          <InfoArea
                              title="When did this happen?"
                              description="Understanding what your insurance company will be looking for also means knowing the importance of time. Did this happen today? Was it this week? Has this been an issue before, a month ago? Year?"
                              icon={Fingerprint}
                              iconColor="success"
                              vertical
                          />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                          <InfoArea
                              title="Where is the damage?"
                              description="Was the damage contained to one area? Was the damage all throughout the house? Was the damage loss in the roof or other main structure?"
                              icon={ThumbUp}
                              iconColor="danger"
                              vertical
                          />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                          <InfoArea
                              title="Why was there an issue?"
                              description="Was there a problem with a loose pipe? Was there a problem with the wiring at the site? Was the roof compromised?"
                              icon={Chat}
                              iconColor="info"
                              vertical
                          />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                          <InfoArea
                              title="The Difference We Make"
                              description="We help you recover all possible benefits so you can get back to your lives, family and business. We cover every part of preparing and settling your claim the whole way through."
                              icon={VerifiedUser}
                              iconColor="success"
                              vertical
                          />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                          <InfoArea
                              title="Avoid The Stress"
                              description="Its our job to help you avoid the stress involved when handling an insurance claim. Everyone's first instinct is the worst case. Going at it alone, experiencing the stress of navigating a complex and unfamiliar process, only relying on the insurance company's assessments. The list goes on."
                              icon={Person}
                              iconColor="danger"
                              vertical
                          />
                      </GridItem>
                  </GridContainer>
              </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
